import React, { useEffect, useState } from "react";
import AppLayout from "../../layouts/AppLayout";
import AddUserModal from "./components/AddUserModal";
import UsersTable from "./components/UsersTable";
import StartSeasonModal from "./components/StartSeasonModal";
import AddCropModal from "./components/AddCropModal";
import CropsTable from "./components/CropsTable";
import { useDispatch, useSelector } from "react-redux";
import { deleteCIPCrop, getAllCIPCrops } from "../../features/crops/cropsSlice";
import { deleteAgriculturalSeason, getAllAgriculturalSeasons } from "../../features/seasons/agriculturalSeasonSlice";
import SeasonsTable from "./components/SeasonsTable";
import { FadeLoader } from "react-spinners";

const roles = [
  {
    id: 1,
    name: "policy_maker",
    display_name: "Policy Maker",
    description:
      "The user account for policy makers enables them to access and analyze relevant data, gather insights, and make informed decisions.",
  },
  {
    id: 2,
    name: "large_hold_farmer",
    display_name: "Large Hold Farmer",
    description:
      "The account for farmers which grants them access to data-driven tools to monitor crop health, track yields, etc",
  },
];
const Settings = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("crops");
  const [showModal, setShowModal] = useState(false);
  const [showSeasonModal, setShowSeasonModal] = useState(false);
  const [showCropModal, setShowCropModal] = useState(false);
  const crops = useSelector((state) => state.crops.data ?? []);
  const isLoadingCropData = useSelector((state) => state.crops.isLoading);
  const seasons = useSelector((state) => state.seasons.data ?? []);
  const isLoadingSeasonsData = useSelector((state) => state.seasons.isLoading);
  // const [crops_data, setCropsData] = useState(crops);
  // const [seasons_data, setSeasonsData] = useState([]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleSeasonModal = () => {
    setShowSeasonModal(!showSeasonModal);
  };

  const toggleCropModal = () => {
    setShowCropModal(!showCropModal);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleDeleteCrop = (cropId) => {
    const payload = {
      id: cropId
    }
    dispatch(deleteCIPCrop(payload));
  }

  const handleDeleteSeason = (seasonId) => {
    const payload = {
      id: seasonId
    }
    dispatch(deleteAgriculturalSeason(payload));
  }

  useEffect(() => {
    dispatch(getAllCIPCrops());
    dispatch(getAllAgriculturalSeasons());
  }, [dispatch]);

  return (
    <AppLayout>
      <div className="py-4 px-6">
        <div className="text-gray-400">
          <h1 className="text-lg font-bold text-white">Settings</h1>
          <div className="py-4">
            <ul
              className="flex flex-wrap -mb-px text-sm font-medium text-center"
              id="myTab"
              data-tabs-toggle="#tabContents"
              role="tablist"
            >
              <li className="mr-2" role="presentation">
                <button
                  className={`inline-block p-4 border-b-2 rounded-t-lg ${
                    activeTab === "seasons"
                      ? "border-gray-500 text-white"
                      : "border-transparent"
                  }`}
                  id="seasons-tab"
                  data-tabs-target="#seasons"
                  type="button"
                  role="tab"
                  aria-controls="seasons"
                  aria-selected={activeTab === "seasons"}
                  onClick={() => handleTabClick("seasons")}
                >
                  {" "}
                  Seasons
                </button>
              </li>
              <li className="mr-2" role="presentation">
                <button
                  className={`inline-block p-4 border-b-2 rounded-t-lg ${
                    activeTab === "crops"
                      ? "border-gray-500 text-white"
                      : "border-transparent"
                  }`}
                  id="crops-tab"
                  data-tabs-target="#crops"
                  type="button"
                  role="tab"
                  aria-controls="crops"
                  aria-selected={activeTab === "crops"}
                  onClick={() => handleTabClick("crops")}
                >
                  {" "}
                  CIP Crops
                </button>
              </li>
            </ul>
          </div>
          <div id="tabContents">
          <div
              className={`p-4 rounded-lg bg-gray-50 ${
                activeTab === "crops" ? "" : "hidden"
              }`}
              id="users"
              role="tabpanel"
              aria-labelledby="users-tab"
            >
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
                <div className="col-span-1 px-4">
                  <h3 className="py-4 font-semibold">Manage CIPs</h3>
                  <p className="text-sm text-gray-600">
                    As an administrator, you can add, manage system users. This
                    tab serves as a centralized location for crops management.
                  </p>
                  <button
                    className="flex w-1/2 mt-4 scroll-py-1.5 my-2 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={toggleCropModal}
                  >
                    Add Crop
                  </button>
                  <AddCropModal
                    showModal={showCropModal}
                    toggleModal={toggleCropModal}
                  />
                </div>
                <div className="col-span-2">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    {isLoadingCropData ? (
                      <FadeLoader color="#52bfd9" size={80} />
                    ) : (
                      <CropsTable crops={crops} deleteCrop={handleDeleteCrop} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`p-4 rounded-lg bg-gray-50 ${
                activeTab === "seasons" ? "" : "hidden"
              }`}
              id="seasons"
              role="tabpanel"
              aria-labelledby="seasons-tab"
            >
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
                <div className="col-span-1 px-4">
                  <h3 className="py-4 font-semibold">Manage seasons</h3>
                  <p className="text-sm text-gray-600">
                    As an administrator, you can add, manage system users. This
                    tab serves as a centralized location for seasons management.
                  </p>
                  <button
                    className="flex w-1/2 mt-4 scroll-py-1.5 my-2 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={toggleSeasonModal}
                  >
                    Start Season
                  </button>
                  <StartSeasonModal
                    showModal={showSeasonModal}
                    toggleModal={toggleSeasonModal}
                  />
                </div>
                <div className="col-span-2">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    {isLoadingSeasonsData ? (
                      <FadeLoader color="#52bfd9" size={80} />
                    ) : (
                      <SeasonsTable seasons={seasons} deleteSeason={handleDeleteSeason} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Settings;
