export const legend = [
    {
        color: '#15803D',
        name: 'Cultivated CropLand',
    },
    {
        color: '#3730A3',
        name: 'Non Cultivated Cropland',
    },
    {
        color: '#09B6D4',
        name: 'Water Bodies',
    },
    {
        color: '#FB923C',
        name: 'No Data Croplands',
    },
];