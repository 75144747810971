import { CloudIcon, PhotoIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PropTypes from 'prop-types';

const DateCarousel = ({ datesData }) => {
  const itemsPerPage = 6;
  const pageCount = Math.ceil(datesData.length / itemsPerPage);
  const slides = [];

  for (let i = 0; i < pageCount; i++) {
    const startIndex = i * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const pageDates = datesData.slice(startIndex, endIndex);

    slides.push(
      <div key={i} className='flex items-center justify-between px-4 2xl:px-12 2xl:mt-4'>
        {pageDates.map((date, index) => (
          <div className='px-8 py-1 2xl:px-12 text-white' key={index}>
                <div role='button' className='flex items-center justify-between grid-cols-3 gap-2'>
                    <PhotoIcon fill='white' className="w-4 h-4" />
                    <span className='text-xs lg:text-md md:text-md 2xl:text-lg'>
                        {new Date(date).toDateString()}
                    </span>
                    <CloudIcon fill='white' className="w-4 h-4" />
                </div>
            </div>
        ))}
      </div>
    );
  }

  return (
    <Carousel showThumbs={false} renderIndicator={false} autoPlay={true} infiniteLoop={true}>
      {slides}
    </Carousel>
  );
};

DateCarousel.propTypes = {
  datesData: PropTypes.array.isRequired,
}

export default DateCarousel;
