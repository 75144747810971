import { memo, useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import PropTypes from 'prop-types';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { useDispatch } from 'react-redux';
import { setChartContainer } from '../../../../features/MapExport/ExportSlice';

const CropsHealthChart = ({ chartData }) => {
  const chartRef = useRef(null);
  const dispatch = useDispatch()
  const [ticking, setTicking] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ticking && count < 15) {
        if(chartData) {
          const chartContainer = chartRef.current;
          html2canvas(chartContainer).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            dispatch(setChartContainer(imgData))
          })
          setCount(prevState => prevState +1);
        }
      }
    }, 2e3)
    return () => clearTimeout(timer)
  }, [chartData, count, dispatch, ticking]);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    const configOptions = {
      darkMode: true,
      backgroundColor: '#0f172a',
      title: {
        text: 'Index Data',
        textStyle: {
          color: '#555',
        },
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        textStyle: {
          color: '#fff',
        },
        data: [`Mean ${chartData.label ?? 'Index'}`, 'Standard Deviation', `Maximum ${chartData.label ?? 'Index'}`, `Minimum ${chartData.label ?? 'Index'}`]
      },
      grid: {
        left: '.5%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: chartData.labels
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false,
        },
      },
      color: ['#588157', '#fff', '#fac858', '#ee6666'],
      series: [
        {
          name: `Mean ${chartData.label ?? 'Index'}`,
          type: 'line',
          data: chartData.data[0],
          smooth: true
        },
        {
          name: 'Standard Deviation',
          type: 'line',
          data: chartData.data[1],
          smooth: true
        },
        {
          name: `Maximum ${chartData.label ?? 'Index'}`,
          type: 'line',
          data: chartData.data[2],
          smooth: true
        },
        {
          name: `Minimum ${chartData.label ?? 'Index'}`,
          type: 'line',
          data: chartData.data[3],
          smooth: true
        }
      ]
    };

    chart.setOption(configOptions);

    return () => {
      chart.dispose();
    }

  }, [chartData])

  return <div className='w-full' ref={chartRef} id='cropsHealthChart' style={{ height: '250px' }}></div>
}

CropsHealthChart.propTypes = {
  chartData: PropTypes.array.isRequired
}

export default memo(CropsHealthChart);