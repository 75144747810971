import { Fragment, useState } from 'react'
import { useLocation } from 'react-router'
import { Dialog, Transition } from '@headlessui/react'
import {
    CogIcon,
    DocumentDuplicateIcon,
    HomeIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import Navigation from '../components/navigation'
import Sidebar from '../components/sidebar'

const AppLayout = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const location = useLocation();
    const { pathname } = location;
    const navigation = [
        { name: 'Home', href: '/', icon: HomeIcon, current: pathname === '/' ? true : false },
        { name: 'Reports', href: '/reports', icon: DocumentDuplicateIcon, current: pathname === '/reports' ? true : false },
        { name: 'Settings', href: '/settings', icon: CogIcon, current: pathname === '/settings' ? true : false },
    ]


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    return (
        <>
            <div className='bg-slate-900 h-full w-auto'>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative flex flex-1 w-full max-w-xs mr-16">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                                            <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="w-6 h-6 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <Navigation navigation={navigation} classNames={classNames} />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                <Sidebar
                    setSidebarOpen={setSidebarOpen}
                    classNames={classNames}
                    navigation={navigation}
                />

                <main className="lg:pl-52 2xl:pl-80 bg-slate-900">
                    <div className="px-1 sm:px-1 lg:px-1 h-full">
                        {children}
                    </div>
                </main>
            </div>
        </>
    )
}

export default AppLayout
