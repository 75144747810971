import axios from "axios";
import { EnvConfig } from "./env.config";

const envConfig = EnvConfig.getInstance();
const API_HOST=envConfig.getApiHost();
const API_PORT=envConfig.getApiPort();

const instance = axios.create({
    baseURL: `${API_HOST}:${API_PORT}`,
    timeout: 100000
}, {
    headers: {
        "Content-Type": "application/json",
    }
});

instance.interceptors.request.use(
    (config) => {
        config.headers["Content-Type"] = "application/json";
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response;
    }, 
    async (error) => {
        const originalRequest = error.config;
        if (error?.message === "Request failed with status code 401" && !originalRequest._retry) {
            try {
                originalRequest._retry = true;
                const refresh_token = localStorage.getItem('refresh_token')
                const res = await instance.post('/auth/token/refresh', {
                    refresh_token
                }).then((result) => result?.data);
    
                localStorage.setItem('token', res.data?.access_token);
                localStorage.setItem('refresh_token', res.data?.refresh_token);
                instance.defaults.headers.common["Authorization"] = `Bearer ${res.data?.access_token}`;
                return await instance(originalRequest);
            } catch (error) {
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('user');
                throw error;
            }
        }   
    }
)

export default instance;