export class EnvConfig {
    envConfig;
    instance;
    constructor () {
        this.envConfig = process.env;
    }

    get(key) {
        return this.envConfig[key];
    }

    getApiHost() {
        return this.get('REACT_APP_API_HOST')
    }

    getApiPort() {
        return this.get('REACT_APP_API_PORT')
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new EnvConfig();
        }
        return this.instance;
    }
}