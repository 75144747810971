import { memo, useEffect, useRef } from "react";
import * as echarts from 'echarts';
import PropTypes from 'prop-types';

const CropsCalendar = ({ chartData }) => {
    const chartRef = useRef(null);
    useEffect(() => {
        const chart = echarts.init(chartRef.current);
        // Chart config
        const configOption = {
            darkMode: true,
            backgroundColor: '#0f172a',
            dataset: {
                source: chartData
            },
            grid: {
                containLabel: true,
                borderWidth: 0,
                show: false,
                z: 0,
                left: '1%',
                top: 30,
                right: '5%',
                bottom: 50,
            },
            xAxis: {
                name: 'days',
                splitLine: {
                    show: true,
                }
            },
            yAxis: {
                type: 'category',
                name: 'Crop'
            },
            visualMap: {
                orient: 'horizontal',
                left: 'center',
                min: 10,
                max: 100,
                text: ['Harvesting', 'Sowing'],
                textStyle: {
                    color: '#B9B8CE'
                },
                // Map the score column to color
                dimension: 'progress',
                inRange: {
                    color: ['#FFF', '#65B581', '#EEC50D ']
                }
            },
            series: [
                {
                    type: 'bar',
                    encode: {
                        x: 'days',
                        y: 'name'
                    }
                }
            ]
        }

        chart.setOption(configOption)

        return () => {
            chart.dispose();
        }
    }, [chartData])

    return <div className='w-full' ref={chartRef} id='cropsCalendarChart' style={{ height: '250px' }}></div>
}

CropsCalendar.propTypes = {
    chartData: PropTypes.array.isRequired
}

export default memo(CropsCalendar);