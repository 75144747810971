import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AppLayout from '../../layouts/AppLayout'
import { Helmet } from 'react-helmet';
import Chart, { CategoryScale } from "chart.js/auto";
import PieChart from './components/charts/PieChart';
import { getCropLandData } from '../../features/crop-land/cropLandSlice';
import { PuffLoader } from 'react-spinners';
import { ArrowUpTrayIcon, PencilIcon, Square3Stack3DIcon, TrashIcon } from '@heroicons/react/24/outline';
import { getAdministrativeBoundary, getUPIBoundaries } from '../../features/administrative-boundary/boundariesSlice';
import CropGraphCarousel from './components/BottomCarousel';
import SearchBox from './components/Search';
import MapComponent from './components/MapComponent';
import { legend } from "../../constants";
import exportMap from "../../helpers/Export";
import HomeSplashScreen from './splash';

Chart.register(CategoryScale);

const Home = () => {
  const dispatch = useDispatch();
  const [pieChartData, setPieChartData] = useState({
    labels: ['Cultivated Ha', 'uncultivated Ha'],
    data: [100, 0],
  });
  const [cropLandValues, setCropLandValues] = useState({
    labels: ['Crops'],
    data: [1],
  });

  const { viewRef, chartContainer, cropsChartContainer, pieChartContainer, selectedIndex } = useSelector(state => state.export);
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {
    name: 'User',
  };

  const [coordinates, setCoordinates] = useState([]);
  const [AOI, setAOI] = useState();
  const admin_boundary = useMemo(() => {
    return {
      ...(AOI && AOI.province && { province: AOI.province }),
      ...(AOI && AOI.district && { district: AOI.district }),
      ...(AOI && AOI.sector && { sector: AOI.sector }),
      ...(AOI && AOI.cell && { cell: AOI.cell }),
      ...(AOI && AOI.village && { village: AOI.village }),
    }
  }, [AOI]);
  const [sketchingEnabled, setSketchingEnabled] = useState(false);
  const [cursorStyle, setCursorStyle] = useState('default');
  const [isReady, setIsReady] = useState(false); // State for rendering a splash screen
  const isLoading = useSelector(state => state.cropLand.isLoading);
  const cropLandData = useSelector(state => state.cropLand.data);
  const administrativeBoundary = useSelector(state => state.administrativeBoundary.data);

  const toggleSketching = () => {
    setSketchingEnabled((prevEnabled) => !prevEnabled);
  };

  /**
   * Get country level crop land data once the app loads
   */
  useEffect(() => {
    const payload = {
      boundary_category: "country",
      boundary_name: "rwanda"
    }
    dispatch(getAdministrativeBoundary(payload));
  }, [dispatch]);

  /**
   * Control the rendering of the splash screen
   */
  useEffect(() => {
    const appHasBeenLaunched = sessionStorage.getItem('hasBeenLaunched');
    if (appHasBeenLaunched)
      setIsReady(true);
    else {
      if (!isLoading) {
        sessionStorage.setItem('hasBeenLaunched', 'true');
        setIsReady(true)
      }
    }

  }, [isLoading]);

  

  /**
   * Get crop land data once the administrative boundary changes
   */
  useEffect(() => {
    if (administrativeBoundary != null) {
      setCoordinates(administrativeBoundary);
    }
  }, [administrativeBoundary, dispatch]);

  useEffect(() => {
    if (coordinates.length > 0) {
      const payload = {
        admin: admin_boundary,
        aoi: coordinates
      }
      dispatch(getCropLandData(payload));
    }
  }, [dispatch, coordinates, admin_boundary])

  useEffect(() => {
    if (cropLandData && typeof cropLandData !== "string") {
      setCropLandValues({
        labels: Object.keys(cropLandData?.area),
        data: Object.values(cropLandData?.area)
      });

      // const nonCultivatedLand = cropLandData?.area?.Area_hectares - cropLandData?.cropland?.cropland_area_hectares;
      const nonCultivatedLand = cropLandData?.area?.cropland - cropLandData?.area?.cultivatedland;

      setPieChartData({
        labels: ['Cultivated', 'Non cultivated'],
        // data: [cropLandData?.cropland?.cropland_area_hectares, nonCultivatedLand]
        data: [cropLandData?.area?.cultivatedland, nonCultivatedLand]
      })
    }
  }, [cropLandData]);

  /**
   * Handle search function. It takes the search result item 
   * (name, category) from the search component
   * It dispatches getAdministrativeBoundary action to get the boundary coordinates
   * @param {name} param0 : search result name. example: Rubavu
   * @param {category} param1 : search result category. example: district or sector
   */
  const handleSearch = useCallback(({ data, type }) => {
    let payload;
    if (type === 'upi') {
      payload = {
        upi: data
      }
      dispatch(getUPIBoundaries(payload))
    } else {
      if (data != null) {
        setAOI(data);
        setCoordinates(data.coordinates);
      }
    }
  }, [dispatch])
  return (
    <>
      {
        isReady ?
          <AppLayout>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
              <MapComponent cursorStyle={cursorStyle} coordinates={coordinates} sketchingEnabled={sketchingEnabled} setCoordinates={setCoordinates} />
              <div className='col-span-full md:col-span-1'>
                <div className="p-2 mb-4 md:mb-1 lg:mb-3 2xl:mb-6 2xl:mt-3">
                  <SearchBox onSearch={handleSearch} />
                </div >
                <div className='grid grid-cols-4 px-4 mb-4 text-white md:mb-2 lg:mb-4'>
                  <div>
                    <PencilIcon role='button' onClick={toggleSketching} className="w-4 h-4" strokeWidth={1.5} stroke='currentColor' />
                  </div>
                  <div>
                    <ArrowUpTrayIcon role='button' className="w-4 h-4" strokeWidth={1.5} stroke='currentColor' />
                  </div>
                  <div>
                    <TrashIcon role='button' className="w-4 h-4" strokeWidth={1.5} stroke='currentColor' />
                  </div>
                  <div>
                    <Square3Stack3DIcon role='button' className="w-4 h-4" strokeWidth={1.5} stroke='currentColor' />
                  </div>
                </div>
                <div className='w-full mb-4 2xl:mb-8 bg-white h-0.5'></div>
                <div className='mb-6 mb:mb-0.5 lg:mb-0 md:mb-1'>
                  {
                    isLoading ?
                      <div className='flex justify-center py-24'>
                        <PuffLoader color="#52bfd9" size={100} />
                      </div>
                      :
                      <>
                        {
                          cropLandData ?
                            <>
                              <div className='flex items-center justify-between mt-2'>
                                <div className='text-white'>
                                  <h4 className='text-base'>Area of Interest statistics</h4>
                                  <p className='text-xs'>Total Area: {(Math.round(cropLandData?.area?.total_area * 100) / 100).toFixed(3)} Ha</p>
                                </div>
                                <div className='px-1'>
                                  <button className='px-1 text-sm text-black bg-white rounded-md 2xl:text-md 2xl:py-1 2xl:px-2'>Save</button>
                                </div>
                              </div>
                            </>
                            :
                            <div className='flex items-center justify-between px-4 mt-3'>
                              <div className='text-white'>
                                <h4 className='text-base 2xl:text-lg'>Farm Name</h4>
                                <p className='text-xs 2xl:text-sm'>Area: (Size) Ha</p>
                              </div>
                              <div className='px-1'>
                                <button className='px-1 text-sm text-black bg-white rounded-md 2xl:text-md 2xl:py-1 2xl:px-2' disabled>Save</button>
                              </div>
                            </div>
                        }
                        <div className='flex items-center justify-center flex-1 py-2'>
                          <PieChart chartData={pieChartData} />
                        </div>
                        <div className='py-4 text-center text-gray-400 2xl:py-2'>
                          <p className='mb-2 text-sm 2xl:text-lg md:text-md'>CropLand: <span className='text-white'>{cropLandData && (Math.round(cropLandData?.area?.cropland * 100) / 100).toFixed(3)} Ha</span></p>
                          <p className='mb-2 text-sm 2xl:text-lg md:text-md'>Non Cultivated: <span className='text-white'>{cropLandData && (Math.round((((cropLandData?.area?.cropland - cropLandData?.area?.cultivatedland) / cropLandData?.area?.cropland) * 100) * 100) / 100).toFixed(3)} %</span></p>
                          <p className='mb-3 text-sm 2xl:text-lg md:text-md'>Cultivated: <span className='text-white'>{cropLandData && (Math.round(((cropLandData?.area?.cultivatedland / cropLandData?.area?.cropland) * 100) * 100) / 100).toFixed(3)} %</span></p>
                        </div>
                        <div className='flex items-center justify-center flex-1 px-3 2xl:py-8'>
                          <button className='w-full font-medium text-black bg-white rounded-full 2xl:py-2' onClick={(e) => {
                            e.preventDefault();
                            exportMap({
                              text: 'Smart Agriculture Report', aoi: AOI?.name ?? 'Rwanda', selectedIndex, viewRef, chartContainer, cropsChartContainer, pieChartContainer, pieChartStatistics: [{
                                name: 'Available Land',
                                value: `${cropLandData && (Math.round(cropLandData?.area?.total_area * 100) / 100).toFixed(3)} Ha`
                              }, {
                                name: 'CropLand',
                                value: `${cropLandData && (Math.round(cropLandData?.area?.cropland * 100) / 100).toFixed(3)} Ha`
                              }, {
                                name: 'Cultivated',
                                value: `${cropLandData && (Math.round(((cropLandData?.area?.cultivatedland / cropLandData?.area?.cropland) * 100) * 100) / 100).toFixed(3)} %`
                              }], legend, user
                            })
                          }}>Export Insights</button>
                        </div>
                      </>
                  }
                </div>
              </div>
            </div>
            <div>
              <CropGraphCarousel coordinates={coordinates} />
            </div>
            <Helmet>
              <link
                href="https://js.arcgis.com/4.27/esri/themes/dark/main.css"
                rel="stylesheet"
                type="text/css"
              />
            </Helmet>
          </AppLayout>
          :
          <HomeSplashScreen />
      }
    </>
  )
}

export default Home